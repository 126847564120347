import React, {useState, useEffect} from 'react';
import loaderIco from '../../img/loader.png';
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import {updateSubscribe} from "../../store/userSlice";
import {useNavigate} from "react-router-dom";

const headers = {
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
};
const Plan = ({plan, isYearly, currentPlan, onToggle}) => {
    const userData = useSelector((state) => state.user.data);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedSubscript, setSelectedSubscript] = useState(null);
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (selectedSubscript !== null) {

            redirect();
        }
    }, [selectedSubscript]);

    const redirect = () => {
        if (userData.loginned === true) {

            window.location.replace(
                `${selectedSubscript.url}?checkout[email]=${userData.email}`
            );
        } else {
            dispatch(
                updateSubscribe({
                    name: selectedSubscript.name,
                    url: `${selectedSubscript.url}?checkout[email]=${userData.email}`,
                    status: 'preparing',
                })
            );
            navigate('/sign');
        }
    };


    const getPaymentUrl = (currentPlan) => {

        axios
            .get(`https://figmaai.sytes.net/subscription/links`, headers)
            .then((res) => {
                console.log(res)
                const getCurrentPlan = res.data.find((p) => p.name === currentPlan);


                // localStorage.setItem('userSubscript', JSON.stringify(res.data));

                // window.location.replace(getCurrentPlan.url);
                setSelectedSubscript(getCurrentPlan);
            })

            .catch((err) => console.error(err));
    };


    return (
        <div className='plan'>
            {isYearly && (
                <>
                    <div className='plan__annual'>
                        <span>Annual Plan</span>
                    </div>
                </>
            )}
            {
                isYearly ? <p className="plan__cta" style={{textDecoration: 'none'}}>
                    $72 billed yearly

                </p> : <p className="plan__cta" onClick={onToggle}>
                    Switch to yearly to get 60% off
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.62012 4.45312L13.6668 8.49979L9.62012 12.5465" stroke="white" stroke-width="1.5"
                              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.3335 8.5H13.5535" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </p>
            }

            <div className='plan__head'>
                <div className='plan__row'>
                    <div className='plan__price'>
                        {isYearly ? plan.prices.year : plan.prices.month}
                    </div>
                    <div className='plan__duration'>/ month</div>
                </div>

                <button
                    className='btn-main btn-main--light'
                    onClick={() => {
                        setLoading(true)
                        setTimeout(() => {
                            console.log('userData.subscript', userData.subscript)
                            if (userData.subscript.status === 'active' || userData.subscript.status === 'on_trial') {
                                navigate('/profile')
                            } else {
                                getPaymentUrl(currentPlan);
                            }

                            // setLoading(false)
                        }, 1000)

                    }}
                >
                    Start your free trial {isLoading && <div
                    className='loader__ico'
                    style={{width: 14, height: 14}}
                >
                    <img src={loaderIco} alt=''/>
                </div>}
                </button>


            </div>
            <ul className='plan__list'>
                {plan.features.map((el) => {
                    return (
                        <li className={'plan__features'}>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.35059 2.25L8.09305 6.75754L12.6006 7.5L8.09305 8.24246L7.35059 12.75L6.60812 8.24246L2.10059 7.5L6.60812 6.75754L7.35059 2.25Z"
                                    fill="white"/>
                            </svg>

                            <div>{el}</div>
                        </li>
                    );
                })}
            </ul>
        </div>
    )
}

export default Plan