//Import React
import React, {useState} from 'react';
import Input from "../components/Input/Input";
import InputPassword from "../components/Input/InputPassword";
import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import {isLoading} from "../store/loaderSlice";
import {useDispatch} from "react-redux";


const ForgotPassword = () => {
    const [isDone, setIsDone] = useState(false)
    const [error, setError] = useState(false)
    const dispatch = useDispatch();
    const methods = useForm()
    const onSubmit = (data) => {
        axios.get(`https://figmaai.sytes.net/forgot-password/${data.email}`)
            .then(res => {

                dispatch(isLoading({loader: true}));

                setTimeout(() => {
                    setIsDone(true)
                    dispatch(isLoading({loader: false}));
                }, 1000);
            })
            .catch((error) => {

                methods.setError("email", {
                    type: "manual",
                    message: 'Email address not found',
                })
                setError(true)
            })
    }

    return (
        <>
            {isDone ? <>
                <div className={'forgot'}>
                    <div className={'forgot__content'}>
                        <div className="forgot__ico">
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 30C12 19.5 18 15 27 15H57C66 15 72 19.5 72 30V51C72 61.5 66 66 57 66H27"
                                      stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M57 31.5L47.61 39C44.52 41.46 39.45 41.46 36.36 39L27 31.5" stroke="white"
                                      stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M12 54H30" stroke="white" stroke-width="3" stroke-miterlimit="10"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 42H21" stroke="white" stroke-width="3" stroke-miterlimit="10"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                        </div>
                        <p className="sign__title">
                            We have sent an email to your inbox for password recovery.
                        </p>
                        <p className="sign__subtitle">
                            Click on the link in the email to reset your password.
                        </p>

                    </div>


                </div>
            </> : <>
                <div className={'forgot'}>
                    <div className={'forgot__content'}>
                        <p className="sign__title">
                            Enter your email address. {error}
                        </p>
                        <p className="sign__subtitle">To reset your password, enter the email address associated with
                            your
                            AI Designer Pro account.</p>
                        <FormProvider {...methods}>
                            <form className='forgot__form' onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="sign__form-inputs">
                                    <Input
                                        object_key={'email'}
                                        name={'email'}
                                        placeholder={'Email address'}
                                        required
                                    />

                                </div>

                                <button
                                    className='btn-main btn-main--large btn-main--purple'
                                    type='submit'>
                                    Reset Password
                                </button>

                            </form>


                        </FormProvider>

                        {error === true && <> <p className={'forgot__create'}>Create a <Link
                            className={'forgot__create-link'} to={'/sign?sign=signup'}>new account</Link> or contact
                            our <a className={'forgot__create-link'}
                                   href={'mailto:support@aidsnpro.com'}>support</a> if you encounter any
                            difficulties.</p>
                        </>}
                    </div>


                </div>
            </>}

        </>
    );
};
export default ForgotPassword;
